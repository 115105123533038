<template>
  <div
    class="flex flex-col"
    v-if="indent && showAll"
    style="font-family: Montserrat; min-height: 100vh"
  >
    <div class="flex-grow-0 pt-24 pb-8 container">
      <div class="mb-16 flex align-center" @click="indent = false">
        <img
          alt="image"
          src="/playground_assets/arrow-left.svg"
          class="mr-4 cursor-pointer"
        />
        <span class="font-semibold tracking-widest cursor-pointer">Back</span>
      </div>
      <div class="mb-8 text-4xl font-bold" style="font-family: Lora">
        Indent
      </div>
      <div class="flex gap-4">
        <div class="w-1/3">
          <div class="mb-4 font-bold" style="font-family: Lora">
            Terms & Conditions
          </div>
          <div>
            We will proceed your product in pre-order. Once you finished the
            payment we will begin for the production.
          </div>
        </div>
        <div class="w-1/3">
          <div class="mb-4 font-bold" style="font-family: Lora">
            Delivery Time
          </div>
          <div>
            Once you finished the payment we will create this product that take
            up to 10 working days
          </div>
        </div>
        <div class="w-1/3">
          <div class="mb-4 font-bold" style="font-family: Lora">
            Down Payment
          </div>
          <div>
            We need your commitment fee {{ downPayment }}% to proceed production
          </div>
        </div>
      </div>
    </div>
    <div
      class="product-detail flex-grow w-full"
      style="background-color: #eae3d9"
    >
      <div class="py-8 container">
        <div class="mb-6 text-2xl font-bold" style="font-family: Lora">
          Product
        </div>
        <div class="flex">
          <!-- <img class="h-32 w-32 mr-4" :src="finalData.product.image.photo" /> -->
          <div class="flex gap-8">
            <div class="w-2/3 flex flex-col justify-between">
              <div class="mb-2 text-2xl font-bold" style="font-family: Lora">
                {{ finalData.product.title }}
              </div>
              <div class="text-lg">
                {{ `IDR ${formatRupiah(finalData.product.price)}` }}
              </div>
              <div class="">
                <div
                  class="mr-4 inline-block"
                  :key="'detail-' + index"
                  v-for="(item, index) in detailData"
                >
                  <div class="font-bold" style="font-family: Lora">
                    {{ item.title }}
                  </div>
                  <div>{{ getOptionValue(item.title) }}</div>
                </div>
                <div class="inline-block">
                  <div class="font-bold" style="font-family: Lora">Qty</div>
                  <div>{{ indent.qty }}</div>
                </div>
              </div>
            </div>
            <div class="w-1/3 flex flex-col justify-between">
              <div class="font-bold" style="font-family: Lora">Total</div>
              <div class="text-2xl font-medium">
                {{ `Rp ${formatRupiah(finalData.product.price * indent.qty)}` }}
              </div>
              <div
                class="w-full py-3 text-white text-center font-semibold tracking-widest cursor-pointer"
                @click="handleIndentAdd"
                style="background-color: #1f252c"
              >
                Add To Cart
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="product-detail-container" v-else-if="product_detail && showAll">
    <div class="max-xl:px-7 xl:px-36 w-full" style="background-color: #eae3d9">
      <div class="product-detail-container-header max-w-rifyo w-full mx-auto">
        <div class="product-detail-container1 mr-8">
          <div
            class="product-detail-container2 cursor-pointer flex gap-3 justify-start items-center"
            @click="goBack()"
          >
            <img
              alt="image"
              src="/playground_assets/arrow-left-black.svg"
              class="product-detail-image p-0"
            />
            <span class="font-bold font-sans text-base">Back All Product</span>
          </div>
          <!-- <img
            alt="image"
            :src="gambar ? gambar : product_detail.image.photo"
            class="product-detail-image1"
          /> -->
          <!-- <div class="product-detail-container3 grid grid-cols-5 gap-1.5">
            <div
              class="cursor-pointer max-lg:h-12 lg:h-24"
              :class="
                gambar_selected === item
                  ? 'product-detail-active'
                  : 'product-detail-nonactive'
              "
              v-for="item in product_detail.image.photo"
              :key="'selected-' + item.id"
              @click="selectedGambar(item)"
            >
              <img
                alt="image"
                :src="item"
                class="product-detail-image2 w-full h-full object-cover"
              />
            </div>
          </div> -->
          <!-- <div class="product-detail-bullet">
            <div class="product-detail-bullet-active"></div>
            <div class="product-detail-bullet-non-active"></div>
            <div class="product-detail-bullet-non-active1"></div>
            <div class="product-detail-bullet-non-active2"></div>
            <div class="product-detail-bullet-non-active3"></div>
          </div> -->
        </div>
        <div class="product-detail-container4 max-lg:pr-0 lg:pr-8 relative">
          <span class="product-detail-text01 font-sans font-normal text-sm">{{
            inStock ? 'IN STOCK' : 'INDENT'
          }}</span>
          <h1
            class="product-detail-text02 max-lg:text-2xl lg:text-4xl font-bold leading-tight"
          >
            {{ productItem.title }}
          </h1>
          <span class="product-detail-text03 max-lg:text-lg lg:text-2xl">{{
            harga
          }}</span>
          <div class="w-custom max-lg:mb-0 lg:mb-32">
            <dropdown-collapse
              :title="item.title"
              v-for="item in productItem.variant_title"
              :key="item.title"
              :child="item.child"
              :update-variant="upVariant.bind(item)"
              :update-toggle="upDropdown.bind(item)"
              :is-active="dropdownActive === item.title"
            ></dropdown-collapse>
          </div>
          <div
            class="z-55 max-lg:fixed lg:absolute bottom-0 left-0 ring-0 max-lg:pr-0 lg:pr-8 w-full"
          >
            <box-indent
              :disabled="finalData && finalData.name_product ? false : true"
              @indent="openIndent"
              :item="finalData"
              ref="boxIndent"
              rootClassName="rootClassName"
              :stock="inStock"
              :text1="productItem.title"
              :text2="harga"
              @share="share"
            ></box-indent>
          </div>
        </div>
      </div>
    </div>
    <div class="product-detail-container-body max-xl:px-7 xl:px-36 w-full">
      <div class="max-w-rifyo mx-auto w-full">
        <div class="product-detail-body-header">
          <div class="product-detail-sku">
            <span class="product-detail-text04">Product SKU</span>
            <span class="product-detail-text05">
              {{ sku }}
            </span>
          </div>
          <div class="product-detail-product">
            <span class="product-detail-text06">{{ manufaktur }}</span>
            <span class="product-detail-text07">{{ etalase }}</span>
          </div>
          <div class="product-detail-warranty">
            <span class="product-detail-text08">Warranty</span>
            <span class="product-detail-text09">{{ productItem.waranty }}</span>
          </div>
          <div class="product-detail-delivery">
            <span class="product-detail-text10">Estimated Delivery Time</span>
            <span class="product-detail-text11">
              {{ inStock ? delivery_time : delivery_indent }}
            </span>
          </div>
        </div>
        <div class="product-detail-tabs space-x-4 md:space-x-8">
          <div
            @click="updateTabs(1)"
            :class="
              tabs === 1
                ? 'product-detail-title-active'
                : 'product-detail-title'
            "
          >
            <span class="product-detail-text font-sans">Description</span>
          </div>
          <div
            @click="updateTabs(2)"
            :class="
              tabs === 2
                ? 'product-detail-title-active'
                : 'product-detail-title'
            "
          >
            <span class="product-detail-text font-sans">Detail</span>
          </div>
          <div
            @click="updateTabs(3)"
            :class="
              tabs === 3
                ? 'product-detail-title-active'
                : 'product-detail-title'
            "
          >
            <span class="product-detail-text font-sans">Designer</span>
          </div>
        </div>
        <div class="product-detail-tabs-content">
          <template>
            <div
              class="prose max-w-full"
              style="font-family: Montserrat"
              v-html="htmlDecode(product_detail.description)"
              v-if="tabs === 1"
            ></div>
          </template>
          <div class="product-detail-content w-full" v-if="tabs === 2">
            <div class="w-full flex flex-wrap -mx-4">
              <div class="w-full md:w-1/2 px-4">
                <div
                  class="prose max-w-full"
                  style="font-family: Montserrat"
                  v-html="htmlDecode(product_detail.detail)"
                ></div>
              </div>
              <div class="w-full md:w-1/2 px-4">
                <div class="flex flex-col space-y-5 items-start">
                  <template v-if="product_detail.brosur !== '[]'">
                    <span class="product-detail-text17">
                      <span class="product-detail-text18"
                        >Download Brochure</span
                      >
                    </span>
                    <div
                      class="product-detail-download"
                      v-for="item in JSON.parse(product_detail.brosur)"
                      :key="'brosur-' + item"
                    >
                      <img
                        alt="image"
                        src="/playground_assets/file.svg"
                        class="product-detail-image7"
                      />
                      <a :href="item" class="product-detail-text19 text-black">
                        {{ getFileName(item) }}
                      </a>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full" v-if="tabs === 3">
            <div v-if="product_detail.designer.type === 'two_persons'">
              <div class="flex flex-col space-y-5">
                <div
                  class="w-full"
                  v-if="
                    product_detail.designer.info[0] &&
                    product_detail.designer.images[0]
                  "
                >
                  <div class="w-full flex flex-wrap -mx-4 -mt-5">
                    <div class="w-full md:w-1/4 px-4 pt-5">
                      <img
                        alt="image"
                        :src="product_detail.designer.images[0]"
                      />
                    </div>
                    <div
                      class="w-full md:w-3/4 px-4 pt-5"
                      v-if="product_detail.designer.info[0]"
                    >
                      <div :class="`w-full`" style="font-family: Montserrat">
                        <div class="mb-8 font-bold" style="font-family: Lora">
                          {{ product_detail.designer.info[0].name }}
                        </div>
                        <div
                          class="mb-8"
                          v-html="product_detail.designer.info[0].description"
                        ></div>

                        <template v-if="product_detail.designer.info[0].studio">
                          <div class="mb-8 font-bold" style="font-family: Lora">
                            Office/Studio
                          </div>
                          <div
                            class="mb-8 studio prose"
                            v-html="product_detail.designer.info[0].studio"
                          ></div>
                        </template>

                        <template v-if="product_detail.designer.info[0].award">
                          <div class="mb-8 font-bold" style="font-family: Lora">
                            Awards/Recognition
                          </div>
                          <div
                            class="mb-8 award prose"
                            v-html="product_detail.designer.info[0].award"
                          ></div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="w-full"
                  v-if="
                    product_detail.designer.info[1] &&
                    product_detail.designer.images[1]
                  "
                >
                  <div class="w-full flex flex-wrap -mx-4 -mt-5">
                    <div class="w-full md:w-1/4 px-4 pt-5">
                      <img
                        alt="image"
                        :src="product_detail.designer.images[1]"
                      />
                    </div>
                    <div
                      class="w-full md:w-3/4 px-4 pt-5"
                      v-if="product_detail.designer.info[1]"
                    >
                      <div :class="`w-full`" style="font-family: Montserrat">
                        <div class="mb-8 font-bold" style="font-family: Lora">
                          {{ product_detail.designer.info[1].name }}
                        </div>
                        <div
                          class="mb-8"
                          v-html="product_detail.designer.info[1].description"
                        ></div>

                        <template v-if="product_detail.designer.info[1].studio">
                          <div class="mb-8 font-bold" style="font-family: Lora">
                            Office/Studio
                          </div>
                          <div
                            class="mb-8 studio prose"
                            v-html="product_detail.designer.info[1].studio"
                          ></div>
                        </template>

                        <template v-if="product_detail.designer.info[1].award">
                          <div class="mb-8 font-bold" style="font-family: Lora">
                            Awards/Recognition
                          </div>
                          <div
                            class="mb-8 award prose"
                            v-html="product_detail.designer.info[1].award"
                          ></div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="product_detail.designer.type === 'personal'">
              <div class="flex flex-col space-y-5">
                <div
                  class="w-full"
                  v-if="
                    product_detail.designer.info[0] &&
                    product_detail.designer.image
                  "
                >
                  <div class="w-full flex flex-wrap -mx-4 -mt-5">
                    <div class="w-full md:w-1/4 px-4 pt-5">
                      <img alt="image" :src="product_detail.designer.image" />
                    </div>
                    <div
                      class="w-full md:w-3/4 px-4 pt-5"
                      v-if="product_detail.designer.info[0]"
                    >
                      <div :class="`w-full`" style="font-family: Montserrat">
                        <div class="mb-8 font-bold" style="font-family: Lora">
                          {{ product_detail.designer.info[0].name }}
                        </div>
                        <div
                          class="mb-8"
                          v-html="product_detail.designer.info[0].description"
                        ></div>

                        <template v-if="product_detail.designer.info[0].studio">
                          <div class="mb-8 font-bold" style="font-family: Lora">
                            Office/Studio
                          </div>
                          <div
                            class="mb-8 studio prose"
                            v-html="product_detail.designer.info[0].studio"
                          ></div>
                        </template>

                        <template v-if="product_detail.designer.info[0].award">
                          <div class="mb-8 font-bold" style="font-family: Lora">
                            Awards/Recognition
                          </div>
                          <div
                            class="mb-8 award prose"
                            v-html="product_detail.designer.info[0].award"
                          ></div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="sc-product-review"
          v-if="product_review && product_review.data"
        >
          <div class="product-detail-buyer-text">
            <h1 class="product-detail-text21 max-lg:text-4xl lg:text-5xl">
              Buyer Review
            </h1>
            <span class="product-detail-text22">
              What our buyers think about this product
            </span>
          </div>
          <div class="loading-review" v-if="loadingReview">
            <svg
              version="1.1"
              id="loader-1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="40px"
              height="40px"
              viewBox="0 0 40 40"
              enable-background="new 0 0 40 40"
              xml:space="preserve"
            >
              <path
                opacity="0.2"
                fill="#000"
                d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                  s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                  c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
              />
              <path
                fill="#000"
                d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                  C22.32,8.481,24.301,9.057,26.013,10.047z"
              >
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 20 20"
                  to="360 20 20"
                  dur="0.5s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </div>
          <div
            class="w-full grid grid-cols-2 grid-flow-row-dense gap-4"
            style="
              grid-template-columns: repeat(2, minmax(250px, 1fr));
              grid-auto-rows: 20px;
            "
          >
            <div
              class="row-start-auto py-4 border-t border-gray-200"
              :key="'col-' + index"
              :style="`grid-row-end: span ${
                $refs[`review${index}`] && $refs[`review${index}`].length
                  ? Math.floor(
                      ($refs[`review${index}`][0].clientHeight + 32) / 28
                    )
                  : 4
              }`"
              v-for="(item, index) in reviews"
            >
              <div :ref="`review${index}`">
                <div class="w-full flex justify-between">
                  <div class="text-lg font-bold" style="font-family: Lora">
                    {{ item.user_info.name }}
                  </div>
                  <div class="flex-none flex">
                    <img
                      class="h-6 w-6"
                      :src="
                        index < item.rate
                          ? '/playground_assets/star-on.svg'
                          : '/playground_assets/star-off.svg'
                      "
                      :key="'thumb-' + index"
                      v-for="(star, index) in 5"
                    />
                  </div>
                </div>
                <div class="mb-2">{{ formatMoment(item.created_at) }}</div>
                <!-- <div class="mb-2 flex gap-2" v-if="item.photo.length">
                  <img
                    class="flex-none object-fit"
                    :key="'image-' + i"
                    :src="img.photo"
                    style="height: 74px; width: 74px"
                    v-for="(img, i) in item.photo"
                  />
                </div> -->
                <div>{{ item.review }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="sc-product-related"
          v-if="
            product_detail.product_section &&
            product_detail.product_section.length > 0
          "
        >
          <div class="product-detail-buyer-text1">
            <h1
              class="product-detail-text25 max-lg:text-4xl lg:text-5xl text-center leading-relaxed"
            >
              Complete<span
                class="max-lg:block lg:inline-block max-lg:ml-0 lg:ml-2"
                >Your Product</span
              >
            </h1>
          </div>
          <div
            class="product-detail-shop-list grid grid-cols-2 lg:grid-cols-4 gap-4"
          >
            <shop-card
              rootClassName="shop-card"
              v-for="item in product_detail.product_section"
              :key="'section-product-' + item.id"
              :image_src="item.image"
              :title="item.title"
              :harga="'IDR ' + formatRupiah(item.price)"
              :item="item"
              @redirect="reload"
            ></shop-card>
          </div>
        </div>
      </div>
    </div>
    <div
      class="product-recomendation max-xl:px-7 xl:px-36 w-full"
      v-if="recommended && recommended.length > 0"
    >
      <div class="max-w-rifyo mx-auto w-full">
        <h1
          class="product-detail-text25 max-lg:text-4xl lg:text-5xl text-center"
        >
          Recommendation
        </h1>
        <div
          class="product-detail-shop-list grid grid-cols-2 lg:grid-cols-4 gap-4"
        >
          <shop-card
            rootClassName="shop-card"
            v-for="item in recommended"
            :key="'indent-' + item.id"
            :image_src="showImage(item)"
            :title="item.title"
            :harga="formatHarga(item)"
            :item="item"
            @redirect="reload"
          ></shop-card>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import DropdownCollapse from '../components/dropdown-collapse';
  import BoxIndent from '../components/box-indent';
  import Footer from '../components/footer';
  import BuyerReview from '../components/buyer-review';
  import ShopCard from '../components/shop-card';
  // import {formatRupiah} from '../lib/index';
  import Api from '../api';
  import _ from 'lodash';
  import moment from 'moment';
  import axios from 'axios';

  export default {
    name: 'ProductDetail',
    props: {},
    data() {
      return {
        indent: false,
        harga: null,
        sku: '-',
        delivery_time: '-',
        delivery_indent: '-',
        gambar: '',
        gambar_selected: null,
        detailData: [],
        finalData: null,
        tabs: 1,
        tabContent: null,
        manufaktur: null,
        etalase: null,
        products: [],
        product_detail: null,
        variants: [],
        dropdownActive: null,
        product_review: [],
        loadingReview: false,
        showAll: true,
        dp: null,
        reviews: [],
        recommended: [],
        randoms: [],
      };
    },
    methods: {
      async getRandomProducts() {
        try {
          const response = await this.$http.get(
            `${Api.baseUrl}/sales?perpage=9999`
          );
          if (response.data.status) {
            let array = response.data.data.filter((product) => {
              return product.id !== this.product_detail.id;
            });
            let random = [];
            for (let index = 0; index < 4; index++) {
              const i = Math.floor(Math.random() * array.length);
              random.push(array[i]);
              array.splice(i, 1);
            }
            this.randoms = random;
          }
        } catch (error) {
          throw error;
        }
      },
      async getRecommended() {
        const slug = this.product_detail.category_slug;
        try {
          const response = await this.$http.get(
            `${Api.baseUrl}/product?category_slug=${slug}`
          );
          if (response.data.status) {
            const array = response.data.data;
            this.recommended = array
              .filter((product) => {
                return product.id !== this.product_detail.id;
              })
              .slice(0, 4);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
      async share() {
        try {
          await navigator.share({
            title: "Rifyo's Product",
            text: this.product_detail.title,
            url: window.location.href,
          });
        } catch (err) {
          console.error(err);
          alert(
            "Your browser doesn't support this share function. Try again on another browser."
          );
        }
      },

      getOptionValue(name) {
        if (this.variants.length) {
          return this.variants.filter((option) => option.title == name)[0].name;
        } else {
          return 'No data';
        }
      },

      formatMoment(time) {
        return moment(time).format('MMMM YYYY');
      },

      async getReviews() {
        const id = this.product_detail.id;
        try {
          const response = await this.$http.get(
            `${Api.baseUrl}/sales/${id}/review`
          );
          if (response.data.status) {
            this.reviews = response.data.data;
          }
        } catch (error) {
          console.error(error);
        }
      },

      async getDP() {
        try {
          if (this.$store.state.isAuthenticated) {
            const response = await axios.get(`${Api.downPaymentUrl}`);
            if (response.data.status) {
              this.dp = response.data.data;
            }
          }
        } catch (error) {
          throw new Error(error);
        }
      },

      reload() {
        this.showAll = false;
        setTimeout(() => {
          location.reload();
        }, 500);
      },

      async handleIndentAdd() {
        if (this.$store.state.isAuthenticated) {
          const content = await axios.post(Api.cartUrl, {
            product_id: this.finalData.product_id,
            qty: this.indent.qty,
          });
          const p_exist = this.$store.state.cart.filter(
            (f) => f.product_id === this.finalData.product_id
          );
          let crt = [];
          if (p_exist.length === 0) {
            crt = this.$store.state.cart.concat({
              ...content.data.data,
              qty: this.jumlah,
            });
          } else {
            crt = this.$store.state.cart.map((f) => {
              if (f.product_id === this.finalData.product_id) {
                f.qty = f.qty + this.jumlah;
              }
              return f;
            });
          }
          this.$store.commit('setCount', crt.length);
          this.$store.commit('setCart', {
            cart: crt,
          });
          this.$router.push('/cart');
        } else {
          this.$router.push('/login');
        }
      },

      openIndent(indent) {
        this.indent = indent;
      },

      getFileName(res) {
        if (res) {
          const sp = res.split('/');
          return sp[sp.length - 1].replaceAll('_', ' ');
        }
        return this.product_detail.title.replaceAll('_', ' ');
      },

      formatRupiah(angka) {
        if (angka) {
          let ang = angka?.toString();
          let number_string = ang?.replace(/[^,\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            separator = '',
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);
          if (ribuan) {
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
          }
          rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
          return rupiah ? rupiah : '';
        }
      },

      formatHarga(item) {
        let variant = [];
        for (let index = 0; index < item?.product_variants?.length; index++) {
          const element = item?.product_variants[index];
          variant = variant.concat(element);
        }
        variant = variant.sort(
          (a, b) =>
            parseFloat(a?.product?.price) - parseFloat(b?.product?.price)
        );
        return 'IDR ' + this.formatRupiah(variant[0]?.product?.price);
      },

      showImage(item) {
        return item.image
          ? item.image.thumbnail
          : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXp7vG6vsG3u77s8fTCxsnn7O/f5OfFyczP09bM0dO8wMPk6ezY3eDd4uXR1tnJzdBvAX/cAAACVElEQVR4nO3b23KDIBRA0ShGU0n0//+2KmO94gWZ8Zxmr7fmwWEHJsJUHw8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwO1MHHdn+L3rIoK6eshsNJ8kTaJI07fERPOO1Nc1vgQm2oiBTWJ+d8+CqV1heplLzMRNonED+4mg7L6p591FC+133/xCRNCtd3nL9BlxWP++MOaXFdEXFjZ7r8D9l45C8y6aG0cWtP/SUGhs2d8dA/ZfGgrzYX+TVqcTNRRO9l+fS5eSYzQs85psUcuzk6igcLoHPz2J8gvzWaH/JLS+95RfOD8o1p5CU5R7l5LkfKEp0mQ1UX7hsVXqDpRrifILD/3S9CfmlUQFhQfuFu0STTyJ8gsP3PH7GVxN1FC4t2sbBy4TNRTu7LyHJbqaqKFw+/Q0ncFloo7CjRPwMnCWqKXQZ75El4nKC9dmcJaou9AXOE5UXbi+RGeJygrz8Uf+GewSn9uXuplnWDZJ7d8f24F/s6iq0LYf9olbS3Q8i5oKrRu4S9ybwaQ/aCkqtP3I28QDgeoK7TBya/aXqL5COx67PTCD2grtdOwH+pQV2r0a7YVBgZoKwwIVFQYG6ikMDVRTGByopjD8ATcKb0UhhRTe77sKs2DV7FKSjId18TUEBYVyLhUThWfILHTDqmI85/2RWWjcE/bhP6OD7maT3h20MHsA47JC3PsW0wcwLhv9t0OOPOIkCn21y2bXXwlyylxiYMPk1SuCSmpfK8bNQvIrpAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwNX4BCbAju9/X67UAAAAASUVORK5CYII=';
      },

      async getProductDetail(slug) {
        try {
          let fullUrl = Api.productSalesUrl + `${slug}`;
          const response = await this.$http.get(fullUrl);
          this.product_detail = response.data.data;
          // this.gambar = this.product_detail.image.photo[0];
        } catch (error) {
          console.log(error);
        }
      },

      async getProductReview(id) {
        try {
          this.loadingReview = true;
          let fullUrl = Api.productSalesUrl + `${id}/review`;
          const response = await this.$http.get(fullUrl);
          this.loadingReview = false;
          this.product_review = response.data.data;
        } catch (error) {
          this.loadingReview = false;
        }
      },

      htmlDecode(input) {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
      },

      updateTabs(tabs) {
        this.tabs = tabs;
        if (tabs === 1) {
          this.tabContent = this.htmlDecode(this.finalData.product.description);
        } else if (tabs === 2) {
          this.tabContent = this.htmlDecode(this.finalData.product.detail);
        } else if (tabs === 3) {
          this.tabContent = null;
        }
      },

      upDropdown(text) {
        this.dropdownActive = text;
      },

      upVariant(variant) {
        const indexSelectedVariant = this.productItem.variant_title.findIndex(
          (item) => item.title == variant.title
        );
        const nextTitle = this.productItem.variant_title[
          indexSelectedVariant + 1
        ]
          ? this.productItem.variant_title[indexSelectedVariant + 1].title
          : null;

        if (nextTitle) {
          setTimeout(() => {
            this.dropdownActive = nextTitle;
          }, 500);
        }

        this.variants = [
          ...this.variants.filter((res) => res.title !== variant.title),
          variant,
        ];
        const res = _.uniqBy(this.productItem.product_variants, function (e) {
          return e.title;
        });

        if (res.length === this.variants.length) {
          let product_id = null;
          let ArrGroupByid = [];
          let i = 0;
          this.productItem.product_variants.forEach((r) => {
            ArrGroupByid[i] = {
              ...r,
              name_product: this.productItem.product_variants
                .filter((f) => f.product_id === r.product_id)
                .map((f) => f.name),
            };
            i++;
          });
          const params = this.variants.map((f) => f.name);
          const result = ArrGroupByid.find(
            (f) => f.name_product.sort().toString() === params.sort().toString()
          );
          if (result) {
            // final data variant by title & value
            this.finalData = result;
            this.sku = this.finalData.product.sku;
            this.delivery_time = this.finalData.product.delivery_time;
            this.delivery_indent = this.finalData.product.delivery_indent;
            this.harga =
              'IDR ' + this.formatRupiah(this.finalData.product.price);
            this.gambar = this.finalData.product.photo;
            this.manufaktur = this.finalData.product.manufacture;
            this.etalase = this.finalData.product.etalase;
          }
          this.updateTabs(1);
        }
        this.updateTabs(1);
      },

      renderPhoto(item) {
        return item?.photo ? JSON.parse(item.photo)[0] : '';
      },

      selectedGambar(item) {
        this.gambar_selected = item;
        // this.gambar = this.renderPhoto(item.image)
        this.gambar = item;
      },

      goBack: function () {
        this.$router.go(-1);
      },

      hargaRendah(item) {
        let variant = [];
        for (let index = 0; index < item?.product_variants?.length; index++) {
          const element = item.product_variants[index];
          variant = variant.concat(element);
        }
        variant = variant.sort(
          (a, b) => parseFloat(a.product.price) - parseFloat(b.product.price)
        );
        return 'IDR ' + this.formatRupiah(variant[0]?.product?.price);
      },

      hargaTinggi(item) {
        let variant = [];
        for (let index = 0; index < item?.product_variants?.length; index++) {
          const element = item.product_variants[index];
          variant = variant.concat(element);
        }
        variant = variant.sort(
          (a, b) => parseFloat(b.product.price) - parseFloat(a.product.price)
        );
        return 'IDR ' + this.formatRupiah(variant[0]?.product?.price);
      },
    },

    async mounted() {
      await this.getProductDetail(this.$route.params.slug);
      await this.getDP();
      await this.getReviews();
      await this.getRecommended();
      await this.getRandomProducts();
      this.dropdownActive = this.productItem.variant_title[0]?.title;
      let arrPrices = [];
      this.productItem?.product_variants.map((variant) => {
        arrPrices.push(variant.product.price);
      });
      this.harga = `IDR ${this.formatRupiah(
        _.min(arrPrices)
      )} - ${this.formatRupiah(_.max(arrPrices))}`;
      this.harga =
        this.hargaRendah(this.productItem) +
        ' - ' +
        this.hargaTinggi(this.productItem);
      // this.gambar = this.productItem.image.thumbnail;
      this.finalData = this.productItem.product_variants[0];
      this.manufaktur = this.finalData.product.manufacture;
      this.etalase = this.finalData.product.etalase;
      this.updateTabs(1);
      this.getProduct(this.finalData.product.category_id);
      this.getProductReview(this.finalData.product_id);
    },

    computed: {
      downPayment() {
        return parseInt(this.dp.value) || false;
      },

      inStock() {
        if (this.finalData?.name_product) {
          return this.finalData.product.stock;
        } else {
          const array = this.product_detail.product_variants;
          let stock = 0;
          for (let index = 0; index < array.length; index++) {
            const element = array[index];
            stock += element.product.stock;
          }
          return stock;
        }
      },
      productItem() {
        const data = this.product_detail?.product_variants;
        let newArr = [];
        for (let index = 0; index < data?.length; index++) {
          const element = data[index];
          newArr = newArr.concat(element);
        }
        let newArr2 = newArr
          .filter((v, i, a) => a.findIndex((t) => t.title === v.title) === i)
          .map((t) => {
            return {
              title: t.title,
              child: newArr
                .filter((v) => v.title === t.title)
                .filter(
                  (v, i, a) => a.findIndex((t) => t.name === v.name) === i
                ),
            };
          });
        this.detailData = newArr2;
        return {
          ...this.product_detail,
          product_variants_new: newArr,
          product_variants_thumb: newArr.filter((t, i) => i % 2 === 0),
          variant_title: newArr2,
        };
      },
      priceRange() {
        const arrPrices = [];

        this.product_detail?.product_variants.map((variant) => {
          arrPrices.push(variant.product.price);
        });

        return `IDR ${this.formatRupiah(
          _.min(arrPrices)
        )} - ${this.formatRupiah(_.max(arrPrices))}`;
      },
    },
    components: {
      DropdownCollapse,
      BoxIndent,
      BuyerReview,
      Footer,
      ShopCard,
    },
  };
</script>

<style>
  div.product-detail-container-body
    div.product-detail-tabs-content
    div.mb-8.award
    ul {
    list-style: disc;
  }
  .product-detail-tabs-content .award.prose p,
  .product-detail-tabs-content .studio.prose p {
    margin: 0;
  }
  .prose p b,
  .Default span {
    font-family: 'Lora';
    color: #1f252c;
  }
  .prose div span {
    line-height: 24px;
    color: #1f252c;
  }
  .prose table {
    width: 100% !important;
  }
  .prose table tr > td {
    width: 50%;
  }
  @media (max-width: 767px) {
    .prose table tr {
      display: flex;
      flex-direction: column;
    }
    .prose table tr > td {
      width: 100%;
      padding: 0;
    }
  }

  @media (max-width: 600px) {
    .shop-card-image1 {
      position: absolute;
      right: 0;
      bottom: 5px;
    }
    .product-recomendation .shop-card-image1 {
      position: absolute;
      right: 5px;
      bottom: 5px;
    }
  }
</style>

<style scoped>
  .w-custom {
    width: 100%;
  }

  .product-recomendation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #eae3d9;
    width: 100%;
    padding: 80px;
    padding: 80px;
  }
  .loading-review {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-detail-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
  }
  .product-detail-container-header {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    padding-top: 120px;
    /* padding-left: var(--dl-space-space-fiveunits); */
    /* padding-right: var(--dl-space-space-fiveunits); */
    flex-direction: row;
    padding-bottom: var(--dl-space-space-fiveunits);
    background-color: #eae3d9;
  }
  .product-detail-container1 {
    flex: 0 0 auto;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-container2 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .product-detail-image {
    width: 24px;
    object-fit: cover;
  }
  .product-detail-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.24em;
    text-transform: uppercase;
  }
  .product-detail-image1 {
    width: 100%;
    object-fit: cover;
    height: 570px;
  }
  .product-detail-container3 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
    overflow: hidden;
  }
  .product-detail-active {
    flex: 0 0 auto;
    /* width: 19%; */
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* background-color: #d9d9d9; */
    background-color: #fff;
    overflow: hidden;
    position: relative;
    /* margin-right: 10px; */
  }
  .product-detail-active:after {
    content: '';
    position: absolute;
    z-index: 9;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .product-detail-image2 {
    object-fit: cover;
    object-position: center center;
    height: 100px;
    width: 100%;
  }
  .product-detail-nonactive {
    flex: 0 0 auto;
    /* width: 19%; */
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
    overflow: hidden;
    /* margin-right: 10px; */
  }
  .product-detail-image3 {
    width: 100px;
    object-fit: cover;
  }
  .product-detail-nonactive1 {
    flex: 0 0 auto;
    width: 19%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
  }
  .product-detail-image4 {
    width: 100px;
    object-fit: cover;
  }
  .product-detail-nonactive2 {
    flex: 0 0 auto;
    width: 19%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
  }
  .product-detail-image5 {
    width: 100px;
    object-fit: cover;
  }
  .product-detail-nonactive3 {
    flex: 0 0 auto;
    width: 19%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
  }
  .product-detail-image6 {
    width: 100px;
    object-fit: cover;
  }
  .product-detail-bullet {
    width: 100%;
    height: 35px;
    display: none;
    align-items: center;
    justify-content: center;
  }
  .product-detail-bullet-active {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: flex-start;
    margin-right: 4px;
    border-radius: 50%;
    flex-direction: column;
    background-color: #1f252c;
  }
  .product-detail-bullet-non-active {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: flex-start;
    margin-right: 4px;
    border-radius: 50%;
    flex-direction: column;
    background-color: #aea79d;
  }
  .product-detail-bullet-non-active1 {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: flex-start;
    margin-right: 4px;
    border-radius: 50%;
    flex-direction: column;
    background-color: #aea79d;
  }
  .product-detail-bullet-non-active2 {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: flex-start;
    margin-right: 4px;
    border-radius: 50%;
    flex-direction: column;
    background-color: #aea79d;
  }
  .product-detail-bullet-non-active3 {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: flex-start;
    margin-right: 4px;
    border-radius: 50%;
    flex-direction: column;
    background-color: #aea79d;
  }
  .product-detail-container4 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-text01 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 4px;
  }
  .product-detail-text02 {
    color: #1f252c;
    margin-top: var(--dl-space-space-unit);
    font-family: Lora;
  }
  .product-detail-text03 {
    color: #1f252c;
    font-size: 24px;
    margin-top: var(--dl-space-space-unit);
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-unit);
  }
  .product-detail-container-body {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-bottom: var(--dl-space-space-fourunits);
    padding-top: var(--dl-space-space-fourunits);
    /* padding-left: var(--dl-space-space-fiveunits); */
    /* padding-right: var(--dl-space-space-sixunits); */
    flex-direction: column;
    background-color: #fff;
  }
  .product-detail-body-header {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 64px;
  }
  .product-detail-sku {
    flex: 0 0 auto;
    width: 35%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #1f252c;
  }
  .product-detail-text04 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .product-detail-text05 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
  }
  .product-detail-product {
    flex: 0 0 auto;
    width: 25%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #1f252c;
  }
  .product-detail-text06 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .product-detail-text07 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
  }
  .product-detail-warranty {
    flex: 0 0 auto;
    width: 15%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #1f252c;
  }
  .product-detail-text08 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .subtitle {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .product-detail-text09 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
  }
  .product-detail-delivery {
    flex: 0 0 auto;
    width: 25%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #1f252c;
  }
  .product-detail-text10 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .product-detail-text11 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
  }
  .product-detail-tabs {
    width: 100%;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #eae3d9;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    cursor: pointer;
  }
  .product-detail-title {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .prose ul > li::before {
    background-color: #374151;
  }
  .product-detail-title-active {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    border-bottom: 2px solid #1f252c;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
    /* font-weight: 700; */
  }

  .product-detail-title1 {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .product-detail-text14 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
    letter-spacing: 3.4px;
  }
  .product-detail-tabs-content {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .product-detail-content {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-text15 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .product-detail-text16 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .product-detail-list {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-list-item {
    width: 100%;
  }
  .product-detail-list1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-list2 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-content1 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-text17 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .product-detail-text18 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .product-detail-download {
    flex: 0 0 auto;
    border: 1px solid #000;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
  }
  .product-detail-image7 {
    width: 24px;
    object-fit: cover;
  }
  .product-detail-text19 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    margin-left: var(--dl-space-space-halfunit);
    letter-spacing: 3.5px;
  }
  .product-detail-download1 {
    flex: 0 0 auto;
    border: 1px solid #000;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
  }
  .product-detail-image8 {
    width: 24px;
    object-fit: cover;
  }
  .product-detail-text20 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    margin-left: var(--dl-space-space-halfunit);
    letter-spacing: 3.5px;
  }
  .product-detail-buyer-text {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: center;
  }
  .product-detail-text21 {
    font-family: Lora;
    font-size: 48px;
    font-weight: bold;
  }
  .product-detail-text22 {
    margin-top: var(--dl-space-space-halfunit);
    font-family: Montserrat;
  }
  .product-detail-container-review {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  }
  .product-detail-review-left {
    flex: 0 0 auto;
    width: 48%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-review-right {
    flex: 0 0 auto;
    width: 48%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-container-rows {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: 24px;
    align-items: center;
    margin-bottom: 24px;
    justify-content: center;
  }
  .product-detail-btn-details {
    flex: 0 0 auto;
    width: 200px;
    border: 1px solid #1f252c;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .product-detail-text23 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 2.5px;
  }
  .product-detail-text24 {
    font-family: Montserrat;
  }
  .product-detail-buyer-text1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-fiveunits);
    align-items: center;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: center;
  }
  .product-detail-text25 {
    font-family: Lora;
    font-size: 48px;
    font-weight: bold;
  }
  .product-detail-shop-list {
    width: 100%;
    margin-top: 45px;
  }
  @media (max-width: 991px) {
    .product-detail-sku {
      margin-bottom: 32px;
    }
    .product-detail-product {
      margin-bottom: 32px;
    }
    .product-detail-warranty {
      margin-bottom: 32px;
    }
    .product-detail-delivery {
      margin-bottom: 32px;
    }
    .w-custom {
      margin-left: -1.75rem;
      width: calc(100% + 3.5rem);
    }
    .product-detail-image1 {
      width: 100%;
      object-fit: cover;
      height: 300px;
    }
    .product-detail-container-header {
      height: 100%;
      flex-wrap: wrap;
      padding-top: 100px;
      /* padding-left: var(--dl-space-space-unit); */
      /* padding-right: var(--dl-space-space-unit); */
    }
    .product-detail-container1 {
      width: 100%;
      height: 100%;
    }
    .product-detail-image {
      padding-top: 4px;
    }
    .product-detail-container3 {
      display: none;
    }
    .product-detail-bullet {
      display: flex;
    }
    .product-detail-container4 {
      order: 2;
      width: 100%;
      padding-top: var(--dl-space-space-oneandhalfunits);
      padding-left: 0px;
    }
    .product-detail-text01 {
      font-size: 20px;
      font-style: normal;
      font-family: Lora;
      font-weight: 700;
      letter-spacing: 0;
    }
    .product-detail-container-body {
      height: auto;
      padding-top: 60px;
      /* padding-top: var(--dl-space-space-unit); */
      /* padding-left: var(--dl-space-space-unit); */
      /* padding-right: var(--dl-space-space-unit); */
    }
    .product-detail-body-header {
      flex-wrap: wrap;
    }
    .product-detail-sku {
      width: 100%;
    }
    .product-detail-product {
      width: 100%;
    }
    .product-detail-warranty {
      width: 100%;
    }
    .product-detail-delivery {
      width: 100%;
    }
    .product-detail-tabs-content {
      flex-wrap: wrap;
    }
    .product-detail-content {
      width: 100%;
    }
    .product-detail-content1 {
      width: 100%;
      margin-top: var(--dl-space-space-oneandhalfunits);
    }
    .product-detail-container-review {
      flex-wrap: wrap;
    }
    .product-detail-review-left {
      width: 100%;
    }
    .product-detail-review-right {
      width: 100%;
    }
    .product-detail-shop-list {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
</style>
